.background--black {
  background-color: #000000;
}
.background--yellow {
  background-color: #f7fed0;
}
.background--yellowDark {
  background-color: #a2971d;
}
.background--red {
  background-color: rgba(255, 4, 4, 0.6);
}
.background--lightRed {
  background-color: rgba(255, 4, 4, 0.1);
}
.background--green {
  background-color: #00c72c;
}
.background--lightGreen {
  background-color: rgba(0, 199, 44, 0.1);
}
.background--primary {
  background-color: #00378B;
}
.background--primary75 {
  background-color: rgba(0, 55, 139, 0.75);
}
.background--primary50 {
  background-color: rgba(0, 55, 139, 0.5);
}
.background--primary15 {
  background-color: rgba(0, 55, 139, 0.15);
}
.background--primary5 {
  background-color: rgba(0, 55, 139, 0.05);
}
.background--white {
  background-color: white;
}
.background--white75 {
  background-color: rgba(255, 255, 255, 0.75);
}
.background--white50 {
  background-color: rgba(255, 255, 255, 0.5);
}
.background--white15 {
  background-color: rgba(255, 255, 255, 0.15);
}
.background--white5 {
  background-color: rgba(255, 255, 255, 0.05);
}
.background--textBlack {
  background-color: rgba(0, 55, 139, 0.75);
}
.background--whiteBg {
  background-color: white;
}
.background--sidebarBg {
  background-color: white;
}
.background--appBg {
  background-color: #b3b3b3;
}
.color--black {
  color: #000000;
}
.color--yellow {
  color: #f7fed0;
}
.color--yellowDark {
  color: #a2971d;
}
.color--red {
  color: rgba(255, 4, 4, 0.6);
}
.color--lightRed {
  color: rgba(255, 4, 4, 0.1);
}
.color--green {
  color: #00c72c;
}
.color--lightGreen {
  color: rgba(0, 199, 44, 0.1);
}
.color--primary {
  color: #00378B;
}
.color--primary75 {
  color: rgba(0, 55, 139, 0.75);
}
.color--primary50 {
  color: rgba(0, 55, 139, 0.5);
}
.color--primary15 {
  color: rgba(0, 55, 139, 0.15);
}
.color--primary5 {
  color: rgba(0, 55, 139, 0.05);
}
.color--white {
  color: white;
}
.color--white75 {
  color: rgba(255, 255, 255, 0.75);
}
.color--white50 {
  color: rgba(255, 255, 255, 0.5);
}
.color--white15 {
  color: rgba(255, 255, 255, 0.15);
}
.color--white5 {
  color: rgba(255, 255, 255, 0.05);
}
.color--textBlack {
  color: rgba(0, 55, 139, 0.75);
}
.color--whiteBg {
  color: white;
}
.color--sidebarBg {
  color: white;
}
.color--appBg {
  color: #b3b3b3;
}
.hSpace--mini {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.hSpace--small {
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.hSpace--default {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.hSpace--medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.hSpace--large {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.vSpace--mini {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.vSpace--small {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.vSpace--default {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.vSpace--medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.vSpace--large {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.vhSpace--mini {
  padding: 0.1rem;
}
.vhSpace--small {
  padding: 0.25em;
}
.vhSpace--default {
  padding: 0.5rem;
}
.vhSpace--medium {
  padding: 1rem;
}
.vhSpace--large {
  padding: 2.5rem;
}
[showBorders1] .KnAppSidebar__box--debug {
  display: block;
}
.KnAppSidebar__inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.KnAppSidebar__expander {
  flex-grow: 1;
}
.KnAppSidebar__box--footer {
  display: flex;
  justify-content: center;
}
.KnAppSidebar__box--debug {
  display: none;
}
.KnAppSidebar {
  background-color: white;
  color: #00378B;
}
.KnAppSidebar::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 15px;
  transform: translateX(100%);
  background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.05;
  content: "";
}
.KnAppSidebar__box {
  padding: 15px;
  border-top: 1px solid rgba(0, 55, 139, 0.15);
}
.KnAppSidebar__box--logo {
  padding: 30px;
}
.KnAppSidebar__box--logo a {
  padding-bottom: 100%;
  display: block;
  background-image: url("~@/assets/imgs/logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.KnAppSidebar__box--footer a {
  padding: 0 0.5em;
}
.KnAppSidebar .BaseButton + .BaseButton {
  margin-top: 0.5em;
}
