
.BaseButton { // css vars
	--transition   : all 0.15s ease;
	--height       : 2em;
	--paddingLeft  : 0.85em;
	--paddingRight : 0.85em;
	--fontFamily   : inherit;
	--fontWeight   : bold;
	--borderRadius : 1em;

	--bgColor-filled          : lighten( blue, 10 );
	--bgColor-filled-hover    : darken( blue, 5 );
	--color-filled            : white;
	--color-filled-hover	  : white;

	--borderWidth-outlined    : 2px;
	--borderStyle-outlined    : solid;
	--borderColor-outlined    : green;

	--bgColor-outlined        : fade( green, 10 );
	--bgColor-outlined-hover  : fade( green, 20 );
	--bgColor-outlined-active : fade( green, 30 );
	--color-outlined          : green;
	--color-outlined-hover    : darken( green, 5 );

	--transform-hover         : scale(1.1);
	--transform-active        : scale(1);
}
.BaseButton { // animation
	@keyframes spin {
		0% { transform: rotate(0); }
		100% { transform: rotate(360deg);  }
	}
}

.BaseButton { // layout
	height: var(--height);
	display: inline-block;
	overflow: hidden;

	&__inner {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100%;
	}
	&__slot {
		display: flex;
		height: 100%;
		align-items: center;
	}
	&__slot--text {
		flex-grow: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	&__slot--spinner {
		position: absolute;
		top: 0; left: 50%; bottom: 0;
		transform: translateX(-50%) scale(75%);
	}
	&__slot:empty {
		display: none;
	}
}
.BaseButton { // styling
	outline: none;
	border: none;
	background-color: transparent;
	line-height: 1em; //var(--height); // this v-centers the text
	user-select: none;
	font-family: var(--fontFamily);
	overflow: visible; // needed for not cutting the :active translateY style
	cursor: pointer;

	&__inner {
		padding-left: var(--paddingLeft);
		padding-right: var(--paddingRight);
		transition: var(--transition);
		border-radius: var(--borderRadius);
	}
	&:hover &__inner {
		transform: scale(1.1);
	}

	&__slot {
		font-family: var(--fontFamily);
		font-weight: var(--fontWeight);
		justify-content: center;
	}
	&__slot--text { justify-content: center; }
	&__slot--before { transform: translateX( -0.25em ); }
	&__slot--after { transform: translateX( 0.25em ); }
	&__slot--spinner { transition: var(--transition); opacity: 0; pointer-events: none; }

	&--showSpinner &__slot--spinner { opacity: 1; }
	&--showSpinner &__slot--spinner svg { animation: spin 1.5s linear infinite both; }
	&--showSpinner &__slot--before { opacity: 0; }
	&--showSpinner &__slot--text { opacity: 0; }
	&--showSpinner &__slot--after { opacity: 0; }
	&--showSpinner { pointer-events: none; }

	&--isFilled &__inner { background-color: var(--bgColor-filled); }
	&--isFilled { color: var(--color-filled); }

	&--isOutlined &__inner { background-color: var(--bgColor-outlined); border: var(--borderWidth-outlined) var(--borderStyle-outlined) var(--borderColor-outlined); }
	&--isOutlined { color: var(--color-outlined); }

	&--isDisabled { opacity: 0.5; pointer-events: none; }
	&--isOutlined&--isDisabled &__inner { background-color: none; border-style: dotted; }

	&:hover&--isFilled &__inner { background-color: var(--bgColor-filled-hover); }
	&:hover&--isFilled &__inner { color: var(--color-filled-hover); }

	&:hover&--isOutlined &__inner { background-color: var(--bgColor-outlined-hover); border-color: var(--color-hover); }
	&:hover&--isOutlined &__inner { color: var(--color-outlined-hover); }

	&:active &__inner { transform: var(--active-transform); }
	&:active&--isOutlined &__inner { background-color: var(--bgColor-outlined-active); }
}
