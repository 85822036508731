
.MhIcon { // vars
	--height : 1em;
	--width : 1em;
	--stroke : currentColor;
	--stroke-width : 2px;
}
.MhIcon { // debug
	[showBorders2] & {}
	//outline: 1px solid red;;
}
.MhIcon { // layout
	display: inline-flex;
	height: var(--height);
	width: var(--width);
	overflow: hidden;

	svg { height: 100%; width: 100%; }
}
.MhIcon { // styling
	svg:not(.noStroke) path {
		stroke: var(--stroke);
		stroke-width: var(--stroke-width);
		vector-effect: non-scaling-stroke;
	}

	&--isSpinning svg { animation: spin 1.5s linear infinite both; }

	@keyframes spin {
		0% { transform: rotate(0); }
		100% { transform: rotate(360deg);  }
	}
}
