
//@import "../../less/vars.less";
//@import "../../less/mixins.less";
//@import (reference) "../../less/atoms.less";

.MhModal { // vars
	--zIndex : 100;
	--modalMaxWidth : 650px;
	--modalBorderRadius : 0.5em;
	--modalBackgroundColor : white;
	--overlayBackgroundColor : fade(black, 35);
	--contentPadding : 1em;
}
.MhModal { // debug
}
.MhModal { // layout
	position: fixed;
	top: 0; left: 0; right: 0; bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: var(--zIndex);

	&__background {
		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;
		background-color: yellow;
	}

	&__modal {
		position: relative;
		width: var(--modalMaxWidth);
		max-width: var(--modalMaxWidth);
		background-color: var(--modalBackgroundColor);

		display: flex;
		flex-direction: column;
		max-height: 90vh;
	}
	&__headerInner {
		display: flex;

		&Left { flex-grow: 1; }
	}
	&__header {
		position: relative;
	}
	&__body {
		position: relative;
		overflow: hidden;
		overflow-y: auto;
	}
	&__footer {
		position: relative;
	}
}
.MhModal { // styling
	&--isHidden {
		opacity: 0;
		pointer-events: none;
	}
	&--isVisible {
		opacity: 1;
		pointer-events: auto;
	}

	&__background {
		background-color: var(--overlayBackgroundColor);
	}
	&__modal {
		border-radius: var(--modalBorderRadius);
	}
	&__header::after { // border bottom
		position: absolute;
		bottom: 0; left: 0; right: 0;
		border-top: 1px solid currentColor;
		content: "";
		opacity: 0.35;
		z-index: 1;
	}
	&__footer::after { // border top
		position: absolute;
		top: 0; left: 0; right: 0;
		border-top: 1px solid currentColor;
		content: "";
		opacity: 0.35;
		z-index: 1;
	}
	&__headerInner,
	&__bodyInner,
	&__footerInner {
		padding: var(--contentPadding);
	}

	&__closeButton {
		cursor: pointer;
		opacity: 0.5;
		&:hover { opacity: 1; }
	}
}
.MhModal {
	transition: all 0.2s ease;

	&__modal {
		transition: all 0.2s ease;
		transform: translateY(0%);
		opacity: 1;
		transform: scale(1);
	}
	&--isHidden &__modal {
		transform: scale(0.5);
		opacity: 0;
	}
}
